
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import Utils from '@/utils'
@Options({
  props: {
    refName: {
      type: String,
      required: true
    }
  }
})
export default class EmpowerInfo extends SuperVue {

  // props
  refName!:string

  active = 0
  menus = [] as any[]

  selectMenu(index, item) {
    this.active = index
    const returnEle = document.querySelector(`#info${ item.page.split('info')[1] }`)
    if (returnEle) {
      returnEle.scrollIntoView(true) // true 是默认的
    }
    document.querySelector(`#info${ item.page.split('info')[1] }`)?.scrollIntoView(true)
  }

  scrollEvent() {
    const scrollTop = this.$refs[`EmpowerInfo${ this.refName }`].scrollTop
    const offsetTopArr = [] as any[]
    this.menus.forEach(item => {
      const offsetTop = document.getElementById(`info${ item.page.split('info')[1] }`)?.offsetTop
      offsetTopArr.push(offsetTop)
    })
    offsetTopArr.forEach((item, index) => {
      if (scrollTop >= offsetTopArr[index]) {
        this.active = index
      }
    })
  }

  mounted() {
    if (this.$refs[`EmpowerInfo${ this.refName }`].children[0].nodeName === 'FORM') {
      for (let i = 0; i < this.$refs[`EmpowerInfo${ this.refName }`].children[0].children.length; i++) {
        this.menus.push({ name: this.$refs[`EmpowerInfo${ this.refName }`].children[0].children[i].children[0].innerText, icon: this.$refs[`EmpowerInfo${ this.refName }`].children[0].children[i].getAttribute('icon'), page: this.$refs[`EmpowerInfo${ this.refName }`].children[0].children[i].getAttribute('id') })
      }
    } else {
      for (let i = 0; i < this.$refs[`EmpowerInfo${ this.refName }`].children.length; i++) {
        this.menus.push({ name: this.$refs[`EmpowerInfo${ this.refName }`].children[i].children[0].innerText, icon: this.$refs[`EmpowerInfo${ this.refName }`].children[i].getAttribute('icon'), page: this.$refs[`EmpowerInfo${ this.refName }`].children[i].getAttribute('id') })
      }
    }
  }

  buttonShow(name) {
    return Utils.showBtn(name)
  }
}
