
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
@Options({
  components: {},
  props: {
    label: {
      type: String,
      required: true
    },
    index: {
      type: String,
      required: true
    }
  }
})
export default class EmpowerInfoItem extends SuperVue {
  // props
  label!:string
  index!:number
}
