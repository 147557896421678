import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-f73be41c")
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "custom-title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "empower-info-item",
    id: `info${_ctx.index}`
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 8, _hoisted_1))
}