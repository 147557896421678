
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import EmpowerInfo from '@/components/empower-info/empower-info.vue'
import EmpowerInfoItem from '@/components/empower-info-item/empower-info-item.vue'
import { httpRequest } from '@/plugins'

@Options({
  components: {
    PagePlugin,
    CustomDialog,
    EmpowerInfo,
    EmpowerInfoItem
  }
})
export default class empower_manage extends SuperVue {
  validateOpenTime(rule, value, callback) {
    if (value < 15) {
      callback(new Error('公示时间不能少于15天'))
    } else {
      callback()
    }
  }
  buttonShow(name) {
    return Utils.showBtn(name)
  }
  auditDialog = {
    open: false,
    title: '审核',
    res_empower_id: 0,
    status: 2,
    reason: ''
  }
  auditCancel() {
    this.auditDialog.open = false
  }
  auditOk() {
    const { res_empower_id, status, reason } = this.auditDialog
    if (status === 3 && reason === '') {
      return this.$message.error('审核意见不能为空')
    }
    httpRequest
      .post('/api/v1/empower_audit/empower/deptAudit', {
        res_empower_id,
        status,
        reason
      })
      .then((res: any) => {
        if (res.code !== '200') return this.$message.error(res.message)
        else {
          this.$message.success(res.message)
          this.auditDialog.open = false
          this.empowers_get_all_data(
            (this.tb.page - 1) * this.tb.pageSize,
            this.tb.pageSize
          )
        }
      })
  }
  empowersAudit(row) {
    this.auditDialog.res_empower_id = row.id
    this.auditDialog.open = true
  }
  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }
  empowers_all_data = []
  empowers_total = 0
  empowers_search_data = {
    name: '',
    user_name: ''
  }
  rule_empowers_search_data = {}
  empowers_detail_dialog = {
    open: false,
    title: '详情'
  }
  empowers_detail_data = {
    // 2021.11.09 新增
    deadline_other: '', // 其他长期使用期限
    source_other: '', // 其他成果来源
    distribution: [] as any[], // 权属内部分配情况
    obligee: '广东工业大学', // 权利人
    rests_type: '', // 其他成果类型
    confer: '', // 之前成果转化或已有商谈情况
    college_name: '',

    name: '',
    code: '',
    empower_way: 0,
    empower_agreement: [] as any[],
    live_time: '',
    result_type: 0,
    finish_person: [] as any[],
    college: '',
    is_level: 0,
    agent: '',
    agent_phone: '',
    team_name: '',
    member: '',
    signtory: '',
    source: 0,
    naturn: 0,
    is_topic: 0,
    topic_time: '',
    detaild_list: [] as any[],
    deputy: '',
    sign_phone: '',
    application_area: '',
    target_customer: '',
    rests_state: '',
    way: [],
    rests_way: '',
    expected_price: 0,
    reserve_price: 0,
    brief: '',
    technical_maturity: 0,
    economic: '',
    society: '',
    risk: ''
  }
  empowers_edit_dialog = {
    open: false,
    title: '指派审批人员',
    current_status: 0
  }
  empowers_edit_data = {
    id: 0,
    users: [],
    userList: [],
    deptList: [],
    sign_type: 0,
    duration: 0,
    open_time: 0
  }
  rule_empowers_edit_data = {
    users: [
      {
        required: true,
        type: 'array',
        messgae: '此项不能为空',
        trigger: 'blur'
      }
    ],
    sign_type: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }
  userList: any[] = []
  empower_way_165 = new Map<any, string>([
    [1, '所有权'],
    [2, '长期使用权']
  ])
  status_167 = new Map<any, string>([
    [0, '草稿'],
    [1, '待成员审核'],
    [2, '审核不通过'],
    [3, '待指派部门审核员'],
    [4, '待部门审核'],
    [5, '待指派科研院审核员'],
    [6, '待科研院审核'],
    [7, '待指派产研院审核员'],
    [8, '待产研院审核'],
    [9, '待指派产研院公示审核员'],
    [10, '待公示审核'],
    [11, '待指派合同评审员'],
    [12, '待合同评审'],
    [13, '待指派权属办合同审批人'],
    [14, '待权属办合同审批'],
    [15, '待指派权属委合同审批人'],
    [16, '待权属委合同审批'],
    [17, '待指派权属委负责人'],
    [18, '待权属委负责人审批'],
    [19, '待指派校章专员'],
    [20, '待盖校章'],
    [21, '审核通过'],
    [22, '待指派权属办异常审核员'],
    [23, '待权属办异常审核'],
    [24, '待指派权属委异常复审员'],
    [25, '待权属委异常复审']
  ])
  result_type_169 = new Map<any, string>([
    [1, '专利'],
    [2, '计算机软件'],
    [3, '集成电路布图设计'],
    [4, '生物医药新品种'],
    [5, '技术秘密'],
    [6, '其他']
  ])
  is_level_177 = new Map<any, string>([
    [0, '正处级以下'],
    [1, '正处级或院士'],
    [2, '副厅级及以上']
  ])
  source_183 = new Map<any, string>([
    [1, '中央和地方财政资金项目'],
    [2, '企业、其他社会组织委托项目'],
    [3, '其他']
  ])
  naturn_184 = new Map<any, string>([
    [0, '否'],
    [1, '是']
  ])
  is_topic_185 = new Map<any, string>([
    [1, '是'],
    [0, '否']
  ])
  technical_maturity_200 = new Map<any, string>([
    [1, '报告级'],
    [2, '方案级'],
    [3, '功能级'],
    [4, '仿真级'],
    [5, '初样级'],
    [6, '正样级'],
    [7, '环境级'],
    [8, '产品级'],
    [9, '系统级'],
    [10, '销售级/应用级']
  ])
  sign_type_205 = new Map<any, string>([
    [2, '并签'],
    [1, '或签']
  ])
  statusShow = {
    get(status:number, deptId:number) {
      // 科研院
      if ([5].includes(status) && deptId === 2) return true
      // 产研院
      else if ([7, 9, 13, 15, 17, 22, 24].includes(status) && deptId === 5) return true
      else return false
    }
  }
  getDeptsByStatus(id: number) {
    Api.http_empower_manageEmpowerGetDepts({ id: id }).then((res) => {
      if (res.code === '200') {
        this.empowers_edit_data.deptList = res.data.data
        this.empowers_edit_dialog.open = true
      } else {
        this.$message.error(res.message)
      }
    })
  }
  empowers_get_all_data(offset = 0, length = 10) {
    const status = [] as number[]
    for (let i = 1; i < 26; i++) status.push(i)
    const postData = {
      offset: offset || 0,
      length: length || 10,
      name: this.empowers_search_data.name,
      user_name: this.empowers_search_data.user_name,
      dept_id: null,
      status
    }
    Api.http_empower_manageempowers0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.empowers_all_data = res.data.data
        this.empowers_total = res.data.total
      }
    })
  }
  empowers_detail_init(row) {
    Api.http_empower_manageempowersget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.empowers_detail_data.name = res.data.name
        // 2021-11-09 新增
        this.empowers_detail_data.deadline_other = res.data.deadline_other
        this.empowers_detail_data.source_other = res.data.source_other
        this.empowers_detail_data.distribution = res.data.distribution
        this.empowers_detail_data.obligee = res.data.obligee
        this.empowers_detail_data.rests_type = res.data.rests_type
        this.empowers_detail_data.confer = res.data.confer
        this.empowers_detail_data.college_name = res.data.college_name

        this.empowers_detail_data.code = res.data.code
        this.empowers_detail_data.empower_way = res.data.empower_way
        this.empowers_detail_data.empower_agreement = res.data.empower_agreement
        this.empowers_detail_data.live_time = res.data.live_time
        this.empowers_detail_data.result_type = res.data.result_type
        this.empowers_detail_data.finish_person = res.data.finish_person
        this.empowers_detail_data.college = res.data.college
        this.empowers_detail_data.is_level = res.data.is_level
        this.empowers_detail_data.agent = res.data.agent
        this.empowers_detail_data.agent_phone = res.data.agent_phone
        this.empowers_detail_data.team_name = res.data.team_name
        this.empowers_detail_data.member = res.data.member
        this.empowers_detail_data.signtory = res.data.signtory
        this.empowers_detail_data.source = res.data.source
        this.empowers_detail_data.naturn = res.data.naturn
        this.empowers_detail_data.is_topic = res.data.is_topic
        this.empowers_detail_data.topic_time = res.data.topic_time
        this.empowers_detail_data.detaild_list = res.data.detaild_list
        this.empowers_detail_data.deputy = res.data.deputy
        this.empowers_detail_data.sign_phone = res.data.sign_phone
        this.empowers_detail_data.application_area = res.data.application_area
        this.empowers_detail_data.target_customer = res.data.target_customer
        this.empowers_detail_data.rests_state = res.data.rests_state
        this.empowers_detail_data.way = res.data.way
        this.empowers_detail_data.rests_way = res.data.rests_way
        this.empowers_detail_data.expected_price = res.data.expected_price
        this.empowers_detail_data.reserve_price = res.data.reserve_price
        this.empowers_detail_data.brief = res.data.brief
        this.empowers_detail_data.technical_maturity
          = res.data.technical_maturity
        this.empowers_detail_data.economic = res.data.economic
        this.empowers_detail_data.society = res.data.society
        this.empowers_detail_data.risk = res.data.risk
        this.empowers_detail_dialog.open = true
        this.setDialogName(this.empowers_detail_dialog.title)
      }
    })
  }
  empowers_detail_cancle() {
    this.empowers_detail_dialog.open = false
    this.removeDialogName()
  }
  empowers_detail_ok() {
    this.empowers_detail_dialog.open = false
    this.removeDialogName()
  }
  empowers_edit_init(row) {
    // this.get_userList(row.id)
    this.empowers_edit_dialog.current_status = row.status

    Api.http_empower_manageempowersget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.empowers_edit_data.id = row.id
        this.empowers_edit_data.users = res.data.users
        this.empowers_edit_data.sign_type = res.data.sign_type
        this.empowers_edit_data.duration = res.data.duration
        this.getDeptsByStatus(row.id)
      }
    })
  }
  empowers_edit_cancle() {
    this.empowers_edit_dialog.open = false
  }
  empowers_edit_ok() {
    this.$refs.ref_empowers_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.empowers_edit_data.id,
          users: this.empowers_edit_data.users,
          sign_type: this.empowers_edit_data.sign_type,
          duration: this.empowers_edit_data.duration
        } as any
        if (this.empowers_edit_dialog.current_status === 9) {
          postData.open_time = this.empowers_edit_data.open_time
        }
        console.log(postData)
        Api.http_empower_manageempowers5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.empowers_edit_dialog.open = false
            this.empowers_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  empowers_init() {
    this.empowers_get_all_data(0, 10)
  }
  get_userList(id = -1) {
    Api.http_empower_manageempowersuserList0({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.userList = res.data.data
      }
    })
  }
  created() {
    this.empowers_init()
  }
}
