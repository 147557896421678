import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5817c648")
const _hoisted_1 = { class: "empower-info" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["item", {'item-active': _ctx.active == index}]),
          key: item.name,
          onClick: ($event: any) => (_ctx.selectMenu(index, item))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(item.icon)
          }, null, 2),
          _createElementVNode("span", null, _toDisplayString(item.name), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: "right",
      ref: `EmpowerInfo${_ctx.refName}`,
      onWheel: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollEvent && _ctx.scrollEvent(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 544)
  ]))
}